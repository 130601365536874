<script setup lang="ts">
const DEFAULT_SIZE = 22
type Props = {
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  size: 22,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    :view-box="`0 0 ${size} ${size}`"
    :width="size"
    :height="size"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_25334_13195)" :transform="`scale(${scale})`">
      <path
        d="M22 11.179c0-6.075-4.925-11-11-11s-11 4.925-11 11 4.925 11 11 11 11-4.925 11-11Z"
        fill="#fff"
      />
      <path
        d="M22 11.179c0-6.075-4.925-11-11-11s-11 4.925-11 11 4.925 11 11 11 11-4.925 11-11Z"
        fill="#FF4471"
        fill-opacity=".6"
      />
      <path
        d="M22 11.179c0-6.075-4.925-11-11-11s-11 4.925-11 11 4.925 11 11 11 11-4.925 11-11Z"
        fill="url(#paint0_linear_25334_13195)"
      />
      <path
        d="M10.996 12.082a4.23 4.23 0 1 0 0-8.462 4.23 4.23 0 0 0 0 8.462Z"
        fill="#FFECF0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.953 16.584c1.557-2.01 4.576-3.374 8.045-3.374 3.468 0 6.487 1.363 8.044 3.374a9.887 9.887 0 0 1-8.045 4.128 9.887 9.887 0 0 1-8.044-4.128Z"
        fill="#FFECF0"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_25334_13195"
        x1="19.433"
        y1="4.029"
        x2="4.033"
        y2="19.429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" stop-opacity=".3" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_25334_13195">
        <path fill="#fff" transform="translate(0 .179)" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
